import request from '@/utils/request';

const appName = "huanxinxiang-fangxianggan";
// 单笔支付
export const alipayNoSignSMSPay = (data, params) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
  params,
});