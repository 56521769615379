import { render, staticRenderFns } from "./watermark.vue?vue&type=template&id=6374ebe2&scoped=true"
import script from "./watermark.vue?vue&type=script&lang=js"
export * from "./watermark.vue?vue&type=script&lang=js"
import style0 from "./watermark.vue?vue&type=style&index=0&id=6374ebe2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6374ebe2",
  null
  
)

export default component.exports